import { useTrans } from '@pkg/ui';
import { cn } from '@pkg/utils';
import { Input } from 'antd';
import { useEffect, useState } from 'react';

const { Search } = Input;

type SearchBoxProps = {
  onSearch: (keywork?: string) => void;
  keyword: string | undefined;
  className?: string;
};

export const SearchBox = ({ onSearch, keyword, className }: SearchBoxProps) => {
  const { t } = useTrans();
  const [inputText, setInputText] = useState(keyword);

  useEffect(() => {
    setInputText(keyword);
  }, [keyword]);

  return (
    <Search
      allowClear
      value={inputText}
      className={cn('w-[300px]', className)}
      placeholder={t('searchPlaceholder')}
      onSearch={(keywork) => onSearch(keywork)}
      onChange={(e) => setInputText(e.target.value)}
    />
  );
};
