import { LeftCircleOutlined, MoonOutlined, RightCircleOutlined, SunOutlined } from '@ant-design/icons';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { Module, modules } from '@pkg/config';
import {
  AcnButton,
  AcnDivider,
  AcnImage,
  AcnLink,
  AcnMenu,
  AcnSpin,
  AuthProvider,
  baseBorderColor,
  useTrans,
} from '@pkg/ui';
import { cn, setCookie } from '@pkg/utils';
import { ConfigProvider, Layout, theme } from 'antd';
import { cloneDeep, isString } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { navItems } from '../constants/consts';
import { useModule } from '../hooks/useModule';
import { usePageLoading } from '../hooks/usePageLoading';
import { useStore } from '../store/app';
import { SwitchLanguage } from './SwitchLanguage';
import { AdminActions } from './header/AdminActions';

const { Header, Sider, Content } = Layout;

type AdminLayoutProps = {
  children: ReactNode;
  className?: string;
};

export const AdminLayout = ({ children, className }: AdminLayoutProps) => {
  const mode = useStore((state) => state.mode);
  const { pageLoading, setPageLoading } = usePageLoading();
  const { t } = useTrans();
  const { module } = useModule();
  const [done, { open }] = useDisclosure(false);

  const [collapsed, setCollapsed] = useLocalStorage({
    key: 'collapsed',
    defaultValue: false,
  });

  const [darkMode, setDarkMode] = useLocalStorage({
    key: 'darkMode',
    defaultValue: mode === 'dark',
  });

  useEffect(() => {
    setTimeout(() => {
      open();
    }, 100);
  }, []);

  if (!done) {
    return (
      <div className={cn('w-screen h-screen grid place-content-center bg-primary')}>
        <AcnSpin />
      </div>
    );
  }

  const handleDarkMode = () => {
    setPageLoading(true);
    setDarkMode(!darkMode);
    setTimeout(() => {
      setPageLoading(false);
    }, 200);
    setCookie('darkMode', !darkMode);
    document.querySelector('#app-wrapper')?.classList.add(darkMode ? 'light' : 'dark');
    document.querySelector('#app-wrapper')?.classList.remove(darkMode ? 'dark' : 'light');
  };

  const items = cloneDeep(navItems)
    .filter((item) => {
      if (!item.children && modules.includes(item.key as Module)) return true;
      else if (item.children) {
        item.children = item.children.filter((sub) => modules.includes(sub.key as Module));
        return item.children.length;
      }
    })
    .map((item) => {
      item.label = isString(item.label) ? t(item.label) : item.label;
      if (item.slug) item.slug = '/' + item.slug;
      if (item.children) {
        item.children = item.children.map((sub) => {
          sub.label = isString(sub.label) ? t(sub.label) : sub.label;
          if (sub.slug) sub.slug = '/' + sub.slug;
          return sub;
        });
      }
      return item;
    });

  return (
    <AcnSpin spinning={pageLoading}>
      <AuthProvider>
        <ConfigProvider theme={{ algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm }}>
          <Layout>
            <Header
              className={cn(
                baseBorderColor,
                'flex items-center px-2 md:px-2 border-b bg-secondary border border-solid'
              )}
            >
              <div className={cn('w-16 h-16 md:w-60 p-2 transition-all duration-200', { 'md:w-16': collapsed })}>
                <AcnLink href={'/'}>
                  <AcnImage src="/favicon.svg" alt="Logo" width={64 * 2} height={64} className="m-auto" />
                </AcnLink>
              </div>

              <div className="flex-grow" />
              <div className="flex items-center gap-4">
                <AcnButton onClick={handleDarkMode} icon={darkMode ? <MoonOutlined /> : <SunOutlined />} />
                <SwitchLanguage />
                <AdminActions />
              </div>
            </Header>

            <Layout>
              <Sider
                collapsible
                width={250}
                theme="light"
                trigger={null}
                collapsed={collapsed}
                className="hidden md:block"
              >
                <AcnMenu
                  selectedKey={[module]}
                  defaultOpenKeys={[items.find((r) => r.children?.find((i) => i.slug === '/' + module))?.key ?? '']}
                  className="h-[calc(100vh-95px)]"
                  items={items}
                />
                <AcnDivider className="!my-0 [&_span]:!p-0" dashed>
                  <AcnButton
                    type="text"
                    size="small"
                    onClick={() => setCollapsed(!collapsed)}
                    icon={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                  />
                </AcnDivider>
              </Sider>

              <Layout>
                <Content className="h-[calc(100vh-93px)] p-3 space-y-3">
                  <div className={cn(className)}>{children}</div>
                </Content>
                <div className="px-4 py-1 text-center bg-secondary dark:text-white">Copyright 2024 ©AcnCore</div>
              </Layout>
            </Layout>
          </Layout>
        </ConfigProvider>
      </AuthProvider>
    </AcnSpin>
  );
};
