import { FilePptOutlined, OrderedListOutlined, ProfileOutlined, TagsOutlined } from '@ant-design/icons';
import { MenuItem } from '@pkg/ui';

export const newsNavItems: MenuItem[] = [
  {
    key: 'news',
    label: 'news.title',
    icon: <ProfileOutlined />,
    children: [
      { key: 'post-categories', label: 'postCategories.title', icon: <OrderedListOutlined />, slug: 'post-categories' },
      { key: 'posts', label: 'post.title', icon: <FilePptOutlined />, slug: 'posts' },
      { key: 'tags', label: 'tag.title', icon: <TagsOutlined />, slug: 'tags' },
    ],
  },
];
