import { Minimal } from '@models/base';
import { PostCreationBody } from '@models/news';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  baseButton,
  baseScrollbar,
  fetchData,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../../base/components';
import { getModule } from '../../../base/helpers/utils';
import { PostApi } from './api';
import { Codes } from './Codes';
import { PostCreationModel } from './create.model';

export const PostCreateContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const postApi = PostApi();

  const formModel = useFormModel(PostCreationModel.model, {});

  const form = useForm<PostCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(PostCreationModel.model),
  });

  const { data } = useQuery<Minimal[]>({
    queryKey: ['post-categories'],
    queryFn: () => {
      return fetchData({ endpoint: 'post-categories' }).then((rs) => {
        if (!rs.data) return [];
        return rs.data;
      });
    },
    enabled: !!formModel.model,
    initialData: () => [],
  });

  useEffect(() => {
    if (data && formModel.model) {
      // const model = cloneDeep(formModel.model);
      // const options: DropdownOptions[] = data.map((r) => ({ label: r.name, value: r._id }));
      // set(model, 'parent.options', options);
      // formModel.setModel(model);
    }
  }, [data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('post.title'), href: '/' + getModule(router) },
    { title: t('post.createTitle') },
  ];

  const onCreate = async (data: PostCreationBody) => {
    const res = await postApi.create(data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('createSuccess'));
    router.push('/' + getModule(router));
  };

  return (
    <div className="space-y-3">
      <Toolbar
        items={breadcrumb}
        moreActions={
          <AcnButton
            disabled={!form.formState.isValid}
            className={cn(baseButton, '!bg-blue-500 !text-white')}
            onClick={form.handleSubmit(onCreate)}
          >
            {t('createBtn')}
          </AcnButton>
        }
      />
      <div className={cn('md:flex gap-3 h-[calc(100vh-175px)] space-y-3 md:space-y-0', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-230px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    fields={['title', 'slug', 'description']}
                    setModel={formModel.setModel}
                  />
                  <Codes formModel={formModel} />
                  <AutoForm
                    model={formModel.model}
                    fields={['content', 'sourceUrl', 'seoTitle', 'seoDescription']}
                    setModel={formModel.setModel}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="md:w-[400px] w-full space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-y-4">
                <AutoForm model={formModel.model} fields={['image', 'tags', 'gallery']} setModel={formModel.setModel} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
