import { slugGenerate } from '@pkg/utils';
import { PostCreationFieldModel } from '../../types';

type PostCreationModelProps = {};

export const PostCreationModel = {
  model: (props: PostCreationModelProps = {}): PostCreationFieldModel => {
    return {
      title: {
        label: 'post.fields.title.label',
        placeholder: 'post.fields.title.placeholder',
        inputType: 'Input',
        name: 'title',
        sideEffect: ['slug'],
        rules: { required: 'post.fields.title.required' },
      },
      slug: {
        label: 'post.fields.slug.label',
        placeholder: 'post.fields.slug.placeholder',
        inputType: 'Input',
        name: 'slug',
        trigger: (fields: Record<keyof PostCreationFieldModel, string | number | boolean | undefined>) => {
          return fields.title ? slugGenerate(fields.title.toString()) : fields.title;
        },
        rules: { required: 'post.fields.slug.required' },
      },
      description: {
        label: 'post.fields.description.label',
        placeholder: 'post.fields.description.placeholder',
        inputType: 'TextArea',
        name: 'description',
        rules: { required: 'post.fields.description.required' },
      },
      content: {
        label: 'post.fields.content.label',
        placeholder: 'post.fields.content.placeholder',
        inputType: 'Editor',
        name: 'content',
        rules: { required: 'post.fields.content.required' },
      },
      image: {
        label: 'post.fields.image.label',
        placeholder: 'post.fields.image.placeholder',
        inputType: 'Image',
        name: 'image',
        rules: { required: 'post.fields.image.required' },
      },
      gallery: {
        label: 'post.fields.gallery.label',
        placeholder: 'post.fields.gallery.placeholder',
        inputType: 'MultipleImage',
        name: 'gallery',
        rules: {},
      },
      tags: {
        label: 'post.fields.tags.label',
        placeholder: 'post.fields.tags.placeholder',
        inputType: 'Dropdown',
        name: 'tags',
        options: [],
        rules: {},
      },
      codes: {
        label: 'post.fields.codes.label',
        placeholder: 'post.fields.codes.placeholder',
        inputType: 'Input',
        name: 'codes',
        rules: {},
      },
      sourceUrl: {
        label: 'post.fields.sourceUrl.label',
        placeholder: 'post.fields.sourceUrl.placeholder',
        inputType: 'Input',
        name: 'sourceUrl',
        rules: {},
      },
      seoTitle: {
        label: 'post.fields.seoTitle.label',
        placeholder: 'post.fields.seoTitle.placeholder',
        inputType: 'Input',
        name: 'seoTitle',
        rules: {},
      },
      seoDescription: {
        label: 'post.fields.seoDescription.label',
        placeholder: 'post.fields.seoDescription.placeholder',
        inputType: 'TextArea',
        name: 'seoDescription',
        rules: {},
      },
    };
  },
};
