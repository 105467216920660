import { FieldTimeOutlined, OrderedListOutlined, ScheduleOutlined, WindowsOutlined } from '@ant-design/icons';
import { MenuItem } from '@pkg/ui';
import { newsNavItems } from '../../news/constants/consts';

export const baseNavItems: MenuItem[] = [
  {
    key: 'crawler',
    label: 'crawler.title',
    icon: <ScheduleOutlined />,
    children: [
      { key: 'tasks', label: 'crawler.task', icon: <OrderedListOutlined />, slug: 'tasks' },
      { key: 'cronjobs', label: 'crawler.cronjob', icon: <FieldTimeOutlined />, slug: 'cronjobs' },
    ],
  },
  {
    key: 'widgets',
    label: 'widget.title',
    icon: <WindowsOutlined />,
    slug: 'widgets',
  },
];

export const navItems: MenuItem[] = [...newsNavItems, ...baseNavItems];
