import { CopyOutlined, DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { AcnButton, AcnDataTable, AcnTooltip, baseBorderColor, OnChange, useTrans, useUser } from '@pkg/ui';
import { cn } from '@pkg/utils';
// TODO: move to @pkg/ui and custom type for DataTable
import { Pagination, TableColumnsType } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { Key, SortOrder } from 'antd/es/table/interface';
import { get, isEmpty } from 'lodash';
import { useModule } from '../hooks/useModule';

type AcnDataTableProps<T> = {
  data: T[];
  pages: { current: number; total: number; limit: number };
  deleted?: boolean;
  columns: TableColumnsType<T>;
  rowsSelected?: string[];
  onSelected?: (keys: string[]) => void;
  onEdit?: (record: T) => void;
  onRestore?: (record: T) => void;
  onRemove?: (record: T) => void;
  onDelete?: (record: T) => void;
  onDuplicate?: (record: T) => void;
  setFilter?: (filter: Record<string, any>) => void;
};

type Sorter = {
  field: string;
  order: 'ascend' | 'descend';
};

export const getSortOrder = <T extends AnyObject>(
  fieldName: keyof T,
  filter: Record<string, any>
): SortOrder | undefined => {
  if (filter.sortBy !== fieldName) return;
  if (filter.sortDirection === 'asc') return 'ascend';
  if (filter.sortDirection === 'desc') return 'descend';
};

export const AdminDataTable = <T extends AnyObject>({
  data,
  pages,
  deleted,
  columns,
  rowsSelected,
  onSelected,
  onDelete,
  onEdit,
  onRemove,
  onRestore,
  onDuplicate,
  setFilter,
}: AcnDataTableProps<T>) => {
  const { t } = useTrans();
  const { roles } = useUser();
  const { module } = useModule();

  const onPaginationChange = (page: number) => {
    setFilter?.({ page });
  };

  const onChange: OnChange = (pagination, filters, sorter) => {
    if (!isEmpty(sorter)) {
      const sort = sorter as Sorter;
      setFilter?.({ sortBy: sort.field, sortDirection: sort.order === 'ascend' || !sort.order ? 'asc' : 'desc' });
    }
  };

  if ([onDelete, onEdit, onRemove, onRestore].some((r) => r)) {
    columns.push({
      width: 150,
      title: t('dataTable.actions'),
      render: (row: T) => {
        return (
          <div className="space-x-2">
            {onDuplicate && !deleted && roles[module]?.includes('add') && (
              <AcnTooltip title={t('dataTable.duplicate')}>
                <AcnButton
                  size="small"
                  className="!bg-teal-600 !text-white !border-0"
                  icon={<CopyOutlined />}
                  onClick={() => onDuplicate(row)}
                />
              </AcnTooltip>
            )}
            {onEdit && !deleted && roles[module]?.includes('edit') && (
              <AcnTooltip title={t('dataTable.edit')}>
                <AcnButton
                  size="small"
                  className="!bg-blue-600 !text-white !border-0"
                  icon={<EditOutlined />}
                  onClick={() => onEdit(row)}
                />
              </AcnTooltip>
            )}
            {onRemove && !deleted && roles[module]?.includes('delete') && (
              <AcnTooltip title={t('dataTable.remove')}>
                <AcnButton
                  size="small"
                  className="!bg-red-500 !text-white !border-0"
                  icon={<DeleteOutlined />}
                  onClick={() => onRemove(row)}
                />
              </AcnTooltip>
            )}

            {onRestore && deleted && roles[module]?.includes('edit') && (
              <AcnTooltip title={t('dataTable.restore')}>
                <AcnButton
                  size="small"
                  className="!bg-sky-600 !text-white !border-0"
                  icon={<ReloadOutlined onClick={() => onRestore(row)} />}
                />
              </AcnTooltip>
            )}
            {onDelete && deleted && roles[module]?.includes('delete') && (
              <AcnTooltip title={t('dataTable.delete')}>
                <AcnButton
                  size="small"
                  className="!bg-red-600 !text-white !border-0"
                  icon={<DeleteOutlined />}
                  onClick={() => onDelete(row)}
                />
              </AcnTooltip>
            )}
          </div>
        );
      },
    });
  }

  return (
    <div>
      <AcnDataTable
        data={data}
        columns={columns}
        onChange={onChange}
        rowsSelected={rowsSelected}
        onSelected={onSelected as (keys: Key[]) => void}
      />
      <Pagination
        showQuickJumper
        showSizeChanger={false}
        onChange={onPaginationChange}
        current={get(pages, 'current', 1)}
        pageSize={get(pages, 'limit', 25)}
        total={get(pages, 'total', 1) * get(pages, 'limit', 25)}
        className={cn(baseBorderColor, 'mt-4 py-[5px] rounded-md text-right pr-2 border border-solid')}
      />
    </div>
  );
};
