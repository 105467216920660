import { SelectOption } from '@pkg/constants';
import { AcnSelect, useTrans } from '@pkg/ui';
import { useRouter } from 'next/router';

export const SwitchLanguage = () => {
  const { t } = useTrans();
  const router = useRouter();

  const options: SelectOption[] = [
    { label: t('languages.vi'), value: 'vi', disabled: router.locale === 'vi' },
    { label: t('languages.en'), value: 'en', disabled: router.locale === 'en' },
  ];

  return (
    <AcnSelect
      options={options}
      value={router.locale}
      onChange={(locale) => router.push(router.pathname, undefined, { locale: locale as string })}
    />
  );
};
