import { Account } from './Account';

export const AdminActions = () => {
  return (
    <div className="flex">
      <div className="flex-grow" />
      <Account />
    </div>
  );
};
