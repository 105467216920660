import { AcnBreadcrumb, AcnButton, BreadcrumbItem, useTrans, useUser } from '@pkg/ui';
import { cn } from '@pkg/utils';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { useModule } from '../hooks/useModule';

type ToolbarProps = {
  items: BreadcrumbItem[];
  deleted?: boolean;
  moreActions?: ReactNode;
  onCreate?: () => void;
  onRestore?: () => void;
  onRemove?: () => void;
  onDelete?: () => void;
  setFilter?: (filter: Record<string, any>) => void;
  selected?: string[];
};

export const Toolbar = ({
  items,
  moreActions,
  onCreate,
  onDelete,
  onRemove,
  onRestore,
  deleted,
  setFilter,
  selected,
}: ToolbarProps) => {
  const { t } = useTrans();
  const { roles } = useUser();
  const { module } = useModule();

  return (
    <div className="items-center block px-4 py-2 space-x-2 space-y-2 bg-secondary md:flex md:space-y-0">
      <AcnBreadcrumb items={items} />
      <div className="flex-grow" />

      {moreActions}

      {setFilter && !deleted && (roles[module]?.includes('edit') || roles[module]?.includes('delete')) && (
        <AcnButton
          className="!bg-blue-600 !text-white !border-0"
          onClick={() => setFilter?.({ deleted: 'true', keyword: '', page: 1 })}
        >
          {t('dataTable.inactive')}
        </AcnButton>
      )}
      {onCreate && !deleted && roles[module]?.includes('add') && (
        <AcnButton onClick={onCreate} className="!bg-green-500 !text-white !border-0">
          {t('dataTable.add')}
        </AcnButton>
      )}
      {onRemove && !deleted && roles[module]?.includes('delete') && (
        <AcnButton
          onClick={() => onRemove()}
          disabled={isEmpty(selected)}
          className={cn('!bg-red-500 !text-white !border-0', { '!bg-red-300': isEmpty(selected) })}
        >
          {t('dataTable.remove')}
        </AcnButton>
      )}

      {setFilter && deleted && (roles[module]?.includes('edit') || roles[module]?.includes('delete')) && (
        <AcnButton
          className="!bg-blue-500 !text-white !border-0"
          onClick={() => setFilter?.({ deleted: false, keyword: '', page: 1 })}
        >
          {t('dataTable.active')}
        </AcnButton>
      )}
      {onRestore && deleted && roles[module]?.includes('edit') && (
        <AcnButton
          onClick={() => onRestore()}
          disabled={isEmpty(selected)}
          className={cn('!bg-sky-500 !text-white !border-0', { '!bg-sky-300': isEmpty(selected) })}
        >
          {t('dataTable.restore')}
        </AcnButton>
      )}
      {onDelete && deleted && roles[module]?.includes('delete') && (
        <AcnButton
          onClick={() => onDelete()}
          disabled={isEmpty(selected)}
          className={cn('!bg-red-600 !text-white !border-0', { '!bg-red-300': isEmpty(selected) })}
        >
          {t('dataTable.delete')}
        </AcnButton>
      )}
    </div>
  );
};
