import { userToken } from '@pkg/constants';
import { AcnAvatar, AcnLink, AcnMenuDropdown, fetchData, useTrans, useUser } from '@pkg/ui';
import { showErrorNoti } from '@pkg/utils';

export const Account = () => {
  const { user, setUser } = useUser();
  const { t } = useTrans();

  const logout = async () => {
    const res = await fetchData({ endpoint: 'users/logout', query: { id: user?._id }, hasAuthen: true });
    if (!res.data) return showErrorNoti(t('mainHeader.logoutSuccess'));
    localStorage.removeItem(userToken);
    setUser();
  };

  const items = [
    {
      key: 'profile',
      label: <AcnLink href="/profile">{t('mainHeader.accountInformation')}</AcnLink>,
    },
    {
      key: 'logout',
      label: (
        <label className="cursor-pointer block w-full" onClick={logout}>
          {t('mainHeader.logout')}
        </label>
      ),
    },
  ];

  return (
    <AcnMenuDropdown items={items}>
      <div className="space-x-1 cursor-pointer">
        <AcnAvatar text={user?.fullName} className="bg-blue-400" /> <span>{user?.fullName}</span>
      </div>
    </AcnMenuDropdown>
  );
};
