import { Minimal } from '@models/base';
import { Post, PostCreationBody } from '@models/news';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  baseButton,
  baseScrollbar,
  fetchData,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../../base/components';
import { getModule } from '../../../base/helpers/utils';
import { useHotkey } from '../../../base/hooks';
import { PostApi } from './api';
import { Codes } from './Codes';
import { PostEditModel } from './edit.model';

export const EditPostContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const postApi = PostApi();

  const id = router.query.id as string;

  const { data, refetch } = useQuery<Post>({
    queryKey: ['post', id],
    queryFn: () => postApi.getById(id).then((rs) => rs.data),
    enabled: !!id,
  });

  const formModel = useFormModel(PostEditModel.model, {});

  const form = useForm<PostCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(PostEditModel.model),
  });

  const { data: categories } = useQuery<Minimal[]>({
    queryKey: ['post-categories'],
    queryFn: () => {
      return fetchData({ endpoint: 'post-categories' }).then((rs) => {
        if (!rs.data) return [];
        return rs.data;
      });
    },
    enabled: !!formModel.model,
    initialData: () => [],
  });

  useEffect(() => {
    if (data) {
      form.reset({ ...data, tags: data.tags?.map((r) => r._id) || [] });
    }
  }, [data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('post.title'), href: '/' + getModule(router) },
    { title: t('post.editTitle') },
  ];

  const onUpdate = async (data: PostCreationBody) => {
    const res = await postApi.update(id, data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  const onUpdateAndPublic = async (data: PostCreationBody) => {
    const res = await postApi.update(id, { ...data, active: true });
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  const onUpdateAndUnpublic = async (data: PostCreationBody) => {
    const res = await postApi.update(id, { ...data, active: false });
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  useHotkey('ctrl+s', form.handleSubmit(onUpdate));

  return (
    <div className="space-y-3">
      <Toolbar
        items={breadcrumb}
        moreActions={
          <>
            <AcnButton
              disabled={!form.formState.isValid}
              className={cn(baseButton, '!bg-blue-500 !text-white')}
              onClick={form.handleSubmit(onUpdate)}
            >
              {t('editBtn')}
            </AcnButton>
            {!data?.active && (
              <AcnButton
                disabled={!form.formState.isValid}
                className={cn(baseButton, '!bg-blue-500 !text-white')}
                onClick={form.handleSubmit(onUpdateAndPublic)}
              >
                {t('editAndPublicBtn')}
              </AcnButton>
            )}
            {data?.active && (
              <AcnButton
                disabled={!form.formState.isValid}
                className={cn(baseButton, '!bg-blue-500 !text-white')}
                onClick={form.handleSubmit(onUpdateAndUnpublic)}
              >
                {t('editAndUnpublicBtn')}
              </AcnButton>
            )}
          </>
        }
      />
      <div className={cn('md:flex gap-3 h-[calc(100vh-175px)] space-y-3 md:space-y-0', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-230px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    fields={['title', 'slug', 'description']}
                    setModel={formModel.setModel}
                  />
                  <Codes formModel={formModel} />
                  <AutoForm
                    model={formModel.model}
                    fields={['content', 'sourceUrl', 'seoTitle', 'seoDescription']}
                    setModel={formModel.setModel}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="md:w-[400px] w-full space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-y-4">
                <AutoForm model={formModel.model} fields={['image', 'tags', 'gallery']} setModel={formModel.setModel} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
